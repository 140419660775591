<template>
    <div class="tradingHall-wrap">
        <van-nav-bar
            left-arrow
            title="交易大厅"
            :border="false"
            @click-left="onClickLeft"
        >
            <template #right>
                <a class="service" :href="store.state.sysConfig.manage" target="_blank"><van-icon :name="themeImgs.tradingHallServiceIcon" /></a>
            </template>
        </van-nav-bar>
        <div class="tradingHall-top-wrap" :style="`background:url(${themeImgs.tradingHallBalanceBgImg})`">
            <span class="title">余额（{{store.state.publicConfig.title}}）</span>
            <span class="balance">{{store.state.memberAmountObj.currency_money || '0.00'}}</span>
        </div>
        <div class="tradingHall-main-wrap">
            <div class="tradingHall-search">
                <van-icon :name="themeImgs.tradingHallAmountIcon" />
                <van-field
                        v-model="search.startAmount"
                        placeholder="请输入起始金额"
                        type="number"
                        @input="(e) => inputChangeUnit(e, 'start')"
                />
                -
                <van-field
                        v-model="search.endAmount"
                        placeholder="请输入最大金额"
                        type="number"
                        @input="(e) => inputChangeUnit(e, 'end')"
                />
            </div>
            <div class="tradingHall-main-box">
                <div class="sort-box">
                    <div class="item-box" @click="handleAmountSort">
                        <span>数量</span>
                        <van-icon :class="{'arrow-up':isAmountArrowUp}" :name="themeImgs.commonArrowDownIcon" />
                    </div>
                    <div class="item-box">
                        <span>支付方式</span>
                    </div>
                    <div class="item-box" @click="handleSellWaySort">
                        <span>出售方式</span>
                        <van-icon :class="{'arrow-up':isSellWayArrowUp}" :name="themeImgs.commonArrowDownIcon" />
                    </div>
                </div>
                <div class="content-box">
                    <van-pull-refresh
                        v-model="isRefreshLoading"
                        success-text="刷新成功"
                        @refresh="onRefresh"
                        v-if="filteredData.length > 0"
                    >
                        <div v-for="(item,index) in filteredData" :key="index">
                            <div class="list-box">
                                <div class="account-box">
                                    <van-image round :src="getAvatar(item.avatarNew)" />
                                    <span>{{Number(item.currency).toFixed(2)}}</span>
                                </div>
                                <div class="paytype-box">
                                    <van-icon :name="themeImgs[`commonPaymentIcon${item1}`]" v-for="(item1,index1) in item.pay_type.split(',')" :key="index1" />
                                    <span  v-if="siteName !== 'qtpay'" class="demolition-sale-text" :class="{'not':item.type == 2}">{{item.type == 1 ? '可拆售' : '不可拆售'}}</span>
                                </div>
                                <div class="buy-btn-box" @click="handleBuy(item, index)">购买</div>
                            </div>
                            <div  v-show="showIndex === index">
                                <div class="triangle"></div>
                                <div class="deal-tips">
                                    <h1>交易提示！</h1>
                                    <p>
                                        {{hintMsg.tradingFloor}}
                                    </p>
                                    <div class="tips-btn">
                                        <div class="tips-btn-cancel" @click="handleBuy(item, null)">取消</div>
                                        <div class="tips-btn-confirm" @click="openUrlBuyCoin(item)">下一步</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </van-pull-refresh>
                    <van-empty
                        v-if="filteredData.length < 1"
                        :image="themeImgs.commonEmptyImg"
                        description="当前暂无交易数据～"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from "vue-router"
import { Toast } from 'vant'
import { getMemberAmount,getSellList,getPaymentList, getSellerInfo, getHint} from '@/api/api'
import { useGetAvatar} from "@hooks";

export default {
    setup () {
        const siteName = ref(siteChannel)
        const store = useStore()
        const $router = useRouter()
        const { getAvatar } = useGetAvatar()
        const hintMsg = ref({})
        // 点击导航栏左侧返回键
        const onClickLeft = () => {
            $router.push('/home')
        }

        //  搜索金额条件
        const search = ref({ startAmount: '', endAmount: '' })
        //  金额输入限制只允许输入整数
        const inputChangeUnit = (e, type) => {
          //    e.target.value.match(/^\d*(\.?\d{0,2})/g)[0] || null
            const amount = e.target.value.match(/^\d+/g) ? e.target.value.match(/^\d+/g)[0] : null
            if (type === 'start') {
                search.value.startAmount = amount
            }
            if (type === 'end') {
                search.value.endAmount = amount
            }
        }

        // 获取可售余额
        const handleGetMemberAmount = () => {
            getMemberAmount().then(res => {
                if(res.result == 200000) {
                    availableBalance.value = res.data.info.currency_money
                }
            })
        }
        const handleGetHint = () => {
            getHint().then(res => {
                if(res.result == 200000) {
                    hintMsg.value = res.data
                }
            })
        }
        onMounted(() => {
            handleGetMemberAmount()
            handleGetSellList()
            handleGetPaymentList()
            handleGetHint()
        })
        // 存储可售余额
        const availableBalance = ref(0)
        // 数量箭头方向是否向上
        const isAmountArrowUp = ref(false)
        // 出售方式箭头方向是否向上
        const isSellWayArrowUp = ref(false)
        // 存储售卖列表数据
        const sellLists = ref({})

        //  执行搜索条件
        const returnData = (data, s, e) => {
          const arr = []
          data.forEach(item => {
            const currency = Number(item.currency)
            //  只有开始金额
            if (s !== 0 && e < s) {
              if (s <= currency) {
                arr.push(item)
              }
            }
            //  只有结束金额
            if (s === 0 && e !== 0) {
              if (currency <= e) {
                arr.push(item)
              }
            }

            //  开始金额和结束都存在
            if (s !== 0 && e !== 0) {
              //    开始金额大于结束金额，换位置
              // if (s > e) {
              //   s += e
              //   e = s - e
              //   s -= e
              // }
              if (s <= currency && currency <= e) {
                arr.push(item)
              }
            }
          })
          return arr
        }

        //  返回数据
        const filteredData = computed(() => {
          if (sellLists.value.length > 0) {
            let start = Number(search.value.startAmount) || 0
            let end = Number(search.value.endAmount) || 0

            if (start || end) {
              const data = returnData(sellLists.value, start, end)
              return data
            }
            return sellLists.value
          }
          return []
        })

      // 获取售卖列表
        const handleGetSellList = () => {
            getSellList().then(res => {
                if(res.result == 200000) {
                    sellLists.value = Object.values(res.data)
                }
            })
        }
        // 下拉刷新
        const isRefreshLoading = ref(false)
        const onRefresh = () => {
            handleGetSellList()
            isRefreshLoading.value = false
        }
        // 计算属性实现视图更新
        const sellInfo = computed(() => store.state.sellInfos)
        // watch监听买卖数据的变化,有消息时，不存在的数据，则新增数组对象，存在的数据，则进行相关操作
        watch(sellInfo, (newValue) => {
            var bool = sellLists.value.some(item=>{
                return item.id == newValue.id
            })
            if(bool) {
                sellLists.value.forEach((item,index) => {
                    if(item.id == newValue.id) {
                        // 修改
                        if(newValue.info_status == 2) {
                            item.currency = newValue.currency
                        }
                        // 删除
                        if(newValue.info_status == 3) {
                            sellLists.value.splice(index,1);
                        }
                    }
                })
            } else {
                // 新增
              if (newValue.info_status !== 3) {
                sellLists.value.push(newValue)
              }
            }
        }, {
            deep: true // name是一个对象，需要进行深度监听
        })
        // 封装排序函数
        function sortArr(attr,rev) {
            return function(a,b) {
                // rev为true的时候从大到小
                if(!!rev) {
                    return b[attr] - a[attr]
                } else {
                    return a[attr] - b[attr]
                }
            }
        }
        // 按出售数量排序
        const handleAmountSort = () => {
            isAmountArrowUp.value = !isAmountArrowUp.value
            sellLists.value = sellLists.value.sort(sortArr('currency',isAmountArrowUp.value))
        }
        // 按出售方式排序
        const handleSellWaySort = () => {
            isSellWayArrowUp.value = !isSellWayArrowUp.value
            sellLists.value = sellLists.value.sort(sortArr('type',isSellWayArrowUp.value))
        }

      // 存储卖家信息
      const sellerInfos = ref({})
      // // 获取卖家卖币信息
      const handleGetSellerInfos = async (id) => {
        const { result, data, msg } = await getSellerInfo({ id })

        if (result === 200000) {
          sellerInfos.value = data.list
          return true
        } else {
          Toast.fail(msg)
          return false
        }
      }
        let showIndex = ref(-1)
        const handleBuy = (item, index) => {
            // 如果提示语是空，则直接跳转
            if (hintMsg.value.tradingFloor) {
                showIndex.value = index
            } else {
                openUrlBuyCoin(item)
            }

        }
        // 跳转买币页面
        const openUrlBuyCoin = async item => {
            console.log(item)
            if(JSON.stringify(paymentList.value) == '{}') {
                $router.push('/addPayment')
                Toast.fail('请添加付款方式')
                return
            } else {
                let buyerPayArr = []
                Object.values(paymentList.value).forEach(item => {
                    buyerPayArr.push(item.type)
                })
                // 获取卖家的收款方式type值
                let sellerPayArr = item.pay_type.split(',').map(Number)
                // 判断买家方式是否包含于卖家方式中
                let bool = sellerPayArr.find(item=>buyerPayArr.includes(item))
                if(!bool) {
                    Toast.fail('请添加对应的付款方式')
                    $router.push('/addPayment')
                    return
                }
            }
            if (!await handleGetSellerInfos(item.id)) return


          $router.push({
                path: 'buyCoin',
                query: {
                  sellerInfos: JSON.stringify(sellerInfos.value)
                }
            })
        }
        // 存储收付款列表数据
        const paymentList = ref({})
        // 获取收付款列表数据
        const handleGetPaymentList = () => {
            getPaymentList().then(res => {
                if(res.result == 200000) {
                    paymentList.value = res.data
                }
            })
        }

        // mapstate--皮肤对应的图片
        const themeImgs = computed(() => store.state.themeImgs)
        return {
            onClickLeft,
            availableBalance,
            isAmountArrowUp,
            isSellWayArrowUp,
            sellLists,
            isRefreshLoading,
            onRefresh,
            handleAmountSort,
            handleSellWaySort,
            openUrlBuyCoin,
            themeImgs,
            store,
            getAvatar,
            handleBuy,
            showIndex,
            hintMsg,
            search,
            inputChangeUnit,
            filteredData,
            siteName
        }
    }
}
</script>

<style lang="less" scoped>
.tradingHall-wrap {
  height: 100%;

    :deep(.van-nav-bar) {
        background: var(--tradingHeadBgColor);
        .van-icon {
            color: var(--tradingHeadTextColor);
        }
        .van-nav-bar__title {
            color: var(--tradingHeadTextColor);
        }
    }
    .service {
        width: 24px;
        height: 24px;
    }
    .tradingHall-top-wrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 114px;
        padding: 17px 0 17px 32px;
        background-size: 100% 100% !important;
        .title {
            font-size: 14px;
            color: var(--balanceBoxTextColor);
            opacity: 0.8;
        }
        .balance {
            font-weight: 700;
            font-size: 34px;
            color: var(--balanceBoxTextColor);
        }
    }
    .tradingHall-main-wrap {
        height: calc(100% - 198px);
        padding: 19px 16px;
        background: var(--mainBgColor);

        .tradingHall-search {
            background: var(--tradingBoxBgColor);
            padding: 0 10px;
            margin-bottom: 10px;
            border-radius: 22px;
            display: flex;
            align-items: center;
            ::v-deep {
                .van-cell {
                   background: transparent;
                }
                .van-icon__image {
                    width: 16px;
                }
            }
        }
        .tradingHall-main-box {
            height: 100%;
            padding: 0 12px;
            background: var(--tradingBoxBgColor);
            border-radius: 8px;
            .sort-box {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                padding: 10px 0;
                .item-box {
                    display: flex;
                    align-items: center;
                    span {
                        color: var(--textCommonColor);
                        font-size: 16px;
                    }
                    :deep(.van-icon) {
                        width: 16px;
                        height: 16px;
                        margin-top: 2px;
                        transform: rotate(0deg);
                        .van-icon__image {
                            width: 16px;
                            height: 16px;
                        }
                        &.arrow-up {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
            .content-box {
                height: calc(100% - 41px);
                overflow-y: scroll;
                .van-pull-refresh {
                    min-height: 100%;
                }
                .list-box {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    // height: 48px;
                    margin: .8rem 0;
                    .account-box {
                        display: flex;
                        align-items: center;
                        flex: 0 0 40%;
                        text-align: left;
                        :deep(.van-image) {
                            width: 32px;
                            height: 100%;
                            margin-right: 14px;
                            .van-image__img {
                                width: 32px;
                                height: 100%;
                            }
                        }
                        span {
                            font-weight: 600;
                            color: var(--textColor);
                        }
                    }
                    .paytype-box {
                        display: flex;
                        align-items: center;
                        flex: 0 0 37%;
                        :deep(.van-icon) {
                            margin-right: 4px;
                            .van-icon__image {
                                width: 16px;
                                height: 16px;
                            }
                        }
                        .demolition-sale-text {
                            font-weight: 500;
                            color: var(--textColor);
                            &.not {
                                color: var(--textCommonColor);
                            }
                        }
                    }
                    .buy-btn-box {
                        flex: 0 0 62px;
                        width: 62px;
                        height: 30px;
                        border-radius: 8px;
                        background: var(--btnBgColor);
                        line-height: 30px;
                        text-align: center;
                        color: #fff;
                        font-weight: 500;
                        font-size: 16px;
                        cursor: pointer;
                    }
                }
                .deal-tips{
                    width: 95%;
                    height: auto;
                    margin: 0 auto;
                    padding-left: .5rem;
                    padding-top: .5rem;
                    padding-right: .5rem;
                    padding-bottom: .5rem;
                    background: var(--tradingDealTipsBgColor);
                    border-radius: .5rem;
                    h1 {
                        font-weight: 700;
                        color: var(--textColor);
                        font-size: .5rem;
                    }
                    p {
                        margin-top: .5rem;
                    }
                    .tips-btn {
                        display: flex;
                        text-align: center;
                        justify-content: right;
                        margin-top: 1rem;
                        .tips-btn-confirm {
                            flex: 0 0 62px;
                            height: 30px;
                            border-radius: 8px;
                            background: var(--btnBgColor);
                            line-height: 30px;
                            text-align: center;
                            color: #fff;
                            font-weight: 500;
                            font-size: 16px;
                            margin-left: 1rem;
                            cursor: pointer;
                        }
                        .tips-btn-cancel {
                            flex: 0 0 62px;
                            width: 62px;
                            height: 30px;
                            border-radius: 8px;
                            line-height: 30px;
                            border: 1px solid;
                            border-color: var(--btnBgColor);
                            text-align: center;
                            color: var(--textColor);
                            font-weight: 500;
                            font-size: 16px;
                            cursor: pointer;
                        }
                    }
                }
                .triangle{
                    position: relative;
                    content: '';
                    top: 0;
                    left: 80%;
                    width: 0;
                    height: 0;
                    border-right:10px solid transparent;
                    border-left:10px solid transparent;
                    border-bottom:10px solid;
                    border-bottom-color: var(--tradingDealTipsBgColor);
                }

            }
        }
    }
}
</style>
